import axios from "axios";
import { navixScribeV2Store } from "./NavixScribeV2Store";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL;

export const createThread = async () => {
  try {
    const response = await axios.post(`${BACKEND_API_URL}/create-thread`);
    return response.data;
  } catch (error) {
    console.error(`Error creating thread: ${error.message}`);
    throw error;
  }
};

export const runAssistant = async (threadId, assistantId, instructions) => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 0));
    const combinedInstructions = navixScribeV2Store.combinedPrompt;

    const response = await axios.post(`${BACKEND_API_URL}/run-thread`, {
      thread_id: threadId,
      assistant_id: assistantId,
      instructions:
        combinedInstructions + (instructions ? `\n\n${instructions}` : ""),
    });
    navixScribeV2Store.setIsAwaitingResponse(false);
    return response.data;
  } catch (error) {
    console.error(`Error running the assistant: ${error.message}`);
    throw error;
  }
};

export const addMessage = async (
  threadId,
  role,
  content,
  setAwaitingResponse = true
) => {
  try {
    navixScribeV2Store.setIsAwaitingResponse(true);
    const response = await axios.post(`${BACKEND_API_URL}/add-message`, {
      thread_id: threadId,
      role,
      content,
    });
    if (!setAwaitingResponse) {
      navixScribeV2Store.setIsAwaitingResponse(false);
    }
    return response.data;
  } catch (error) {
    navixScribeV2Store.setIsAwaitingResponse(false);
    console.error(`Error adding message: ${error.message}`);
    throw error;
  }
};

export const fetchMessages = async (threadId) => {
  try {
    const response = await axios.get(
      `${BACKEND_API_URL}/get-messages/${threadId}`
    );
    return response.data;
  } catch (error) {
    console.error(`Error fetching messages: ${error.message}`);
    throw error;
  }
};

export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);

    const response = await axios.post(
      `${BACKEND_API_URL}/upload-file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data.file_id;
  } catch (error) {
    console.error(`Error uploading file: ${error.message}`);
    throw error;
  }
};

export const attachFileToThread = async (threadId, fileId, content) => {
  try {
    const response = await axios.post(
      `${BACKEND_API_URL}/attach-file-to-thread`,
      {
        thread_id: threadId,
        file_id: fileId,
        content,
      }
    );
    return response.data.message_id;
  } catch (error) {
    console.error(`Error attaching file to thread: ${error.message}`);
    throw error;
  }
};

export const addFileToThread = async (threadId, file) => {
  try {
    const fileId = await uploadFile(file);
    const messageId = await attachFileToThread(
      threadId,
      fileId,
      `File attached: ${file.name}`
    );
    return { fileId, messageId };
  } catch (error) {
    console.error(`Error adding file to thread: ${error.message}`);
    throw error;
  }
};
